<template>
    <section>
        <div class="d-flex mb-1">
                <div class="flex-grow-1">
                    <div class="d-flex">
                        <span class="me-2">
                            <font-awesome-icon :icon="['fas', 'cogs']"></font-awesome-icon>
                        </span>
                        <h3 class="text-break">
                            {{ element.title.length > 50 ? `${element.title.slice(0,50)} ...` : element.title }}
                        </h3>
                    </div>
                </div>
                <div>
                    <router-link target='_blank' :to="`${element.url_path}`">
                        <font-awesome-icon :icon="['fas', 'ellipsis-h']"></font-awesome-icon>
                    </router-link>
                </div>
            </div>
            <div class="d-flex align-items-top">
                <div class="flex-grow-1">
                    <div class="d-flex">
                        <span class="me-2"></span>
                        <p class="task-tag ps-3"><i class="fas fa-tag"></i> <span v-if="element.sub_title"> 
                            {{ element.sub_title.slice(0,35) }}
                            <span v-if="element.sub_title.length < 35"> ... </span></span></p>
                    </div>
                </div>

                <div class="d-flex flex-shrink-0" v-if="element.approverinfo">
                    <a href="#" class="flex-shrink-0" v-for="(info, index) in element.approverinfo" :key="index">
                        <div class="link-icon rounded-circle" v-if="info.photo">
                            <img :src="info.photo" class="img-fluid svg-icon"
                                    :alt="info.name">
                        </div>
                    </a>
                   
                </div>
            </div>
            <div class="d-flex ps-3">
                <div class="task-category rounded-pill"> {{element.status}}</div>
                <div class="task-category rounded-pill"> (V:{{element.version }})</div>
            </div>
    </section>
</template>
<script>
    export default {
        props: {
            element: {
                type: Object,
                required: false
            }
        },
        
    }
</script>